var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-container"},[_c('div',{staticClass:"table-header"},[_vm._v(" "+_vm._s(_vm.table_header)+" ")]),_c('b-table',{attrs:{"items":_vm.items,"fields":[{ key: 'section', label: this.column_name, sortable: true }].concat(
        _vm.fields
      ),"small":"","responsive":"","thead-class":"custom-header small","table-class":"simple-report-table small","bordered":""},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [_c('div',{staticClass:"custom-table-header",class:{
          'red-background':
            data.field.label == 'RANGO INSUFICIENTE' ||
            data.field.label == 'INSUFICIENTE',
          'yellow-background':
            data.field.label == 'RANGO SUFICIENTE' ||
            data.field.label == 'SUFICIENTES',
          'green-background':
            data.field.label == 'RANGO DESTACADO' ||
            data.field.label == 'DESTACADOS',
        }},[_vm._v(" "+_vm._s(data.field.label)+" ")])]}},{key:"cell()",fn:function(data){return [[(data.field.key != 'section' && data.field.key != 'ENUNCIADO')?_c('div',[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"amount-caption",class:{
                'red-background': data.field.label == 'RANGO INSUFICIENTE',
                'yellow-background': data.field.label == 'RANGO SUFICIENTE',
                'green-background': data.field.label == 'RANGO DESTACADO',
              }},[_vm._v("CANTIDAD")]),_c('span',{staticClass:"percent-caption",class:{
                'red-background': data.field.label == 'RANGO INSUFICIENTE',
                'yellow-background': data.field.label == 'RANGO SUFICIENTE',
                'green-background': data.field.label == 'RANGO DESTACADO',
              }},[_vm._v("PORCENTAJE")])]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"amount-value"},[_vm._v(" "+_vm._s(data.value[0])+" ")]),_c('span',{staticClass:"percent-value"},[_vm._v(" "+_vm._s(data.value[1])+" % ")])])]):(data.field.label == 'SECCIÓN' && data.value != 'TOTAL')?_c('div',[_c('span',{attrs:{"id":`popover-professor-section-${data.index}`}},[_vm._v(" "+_vm._s(data.value[0])+" ")]),_c('b-popover',{attrs:{"target":`popover-professor-section-${data.index}`,"triggers":"hover"}},_vm._l((data.value[1]),function(professor,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(professor)+" ")])}),0)],1):_c('div',[_vm._v(" "+_vm._s(data.value)+" ")])]]}}])}),(!_vm.chartdata.datasets.length)?[_c('div',{staticClass:"empty-dataset-alert"},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"exclamation-circle"}}),_vm._v(" No hay datos para mostrar. ")],1)]:_vm._e(),(_vm.chartdata.extra_data)?[_c('div',{staticClass:"report-ranges-caption"},[_c('div',{staticClass:"report-ranges-header"},[_vm._v("RANGOS DE LOGROS")]),_vm._l((_vm.chartdata.extra_data
          .achievement_ranges),function(grade_range,index){return [_c('div',{key:grade_range.id,staticClass:"report-range"},[_c('span',{staticClass:"range-label"},[_vm._v(" "+_vm._s(_vm.chartdata.extra_data.achievement_ranges[index][0])+" ")]),_c('div',{class:{
              'range-value-red': index == 0,
              'range-value-yellow': index == 1,
              'range-value-green': index == 2,
            }},[_vm._v(" "+_vm._s(_vm.chartdata.extra_data.achievement_ranges[index][1] + "%")+" - "+_vm._s(_vm.chartdata.extra_data.achievement_ranges[index][2] + "%")+" ")])])]})],2),_c('div',{staticClass:"report-ranges-caption mb-2"},[_vm._m(0),_vm._l((_vm.chartdata.datasets),function(grade_range,index){return [(grade_range.label != 'TOTAL')?_c('div',{key:'teacher_extra_data:' + index,staticClass:"report-range ml-4"},[_c('span',{staticClass:"range-label"},[_vm._v(" "+_vm._s(grade_range.label[0])+" ")]),_vm._l((grade_range.label[1]),function(teacher,index2){return _c('div',{key:index2 + '-' + index,staticClass:"ml-1"},[_vm._v(" "+_vm._s(teacher)),(index2 + 1 != grade_range.label[1].length)?_c('span',[_vm._v(",")]):_vm._e()])})],2):_vm._e()]})],2)]:_vm._e(),[_c('download-excel',{staticClass:"btn btn-primary mt-2",attrs:{"data":_vm.itemsXLS,"fields":_vm.jsonFields,"worksheet":`REPORTES RANGOS ${_vm.$getVisibleNames(
        'mesh.egressprofilematter',
        false,
        'Asignatura'
      ).toUpperCase()}`,"name":`Reporte Assessment Rangos (${_vm.$getVisibleNames(
        'mesh.egressprofilematter',
        false,
        'Asignatura'
      )}).xls`,"header":_vm.headerArray,"footer":_vm.footerArray}},[_vm._v(" Descargar XLS ")])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-ranges-header"},[_c('div',{staticClass:"report-range"},[_c('span',{staticClass:"range-label"},[_vm._v(" SECCIÓN ")]),_c('div',[_vm._v("DOCENTES")])])])
}]

export { render, staticRenderFns }